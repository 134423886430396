<template>
  <div>
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-data-table
        :headers="headers"
        :items.sync="customer.customerManuals"
        :search="search"
        class="elevation-1"
        item-key="manual_s3_path"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-toolbar-title>MANUALS</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <template>
              <new-manual-dialog :items="customer.customerManuals" />
            </template>
          </v-toolbar>
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <v-icon @click="downloadManual(item.manual_s3_path)">
            mdi-download
          </v-icon>
        </template>
        <template v-slot:[`item.manual_s3_path`]="{ item }">
          {{ item.manual_s3_path.split('/manuals/')[1] }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <edit-manuals-label :item="item" />
          <v-icon
            small
            class="mx-1"
            @click="openTranslationTab(item)"
          >
            mdi-google-translate
          </v-icon>
          <delete
            :id="item.name"
            @deleted="deleteItem(item)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary"> No manuals users yet. Add some! </span>
        </template>
      </v-data-table>
    </v-card>
    <manuals-label-translation
      v-if="isTranslating"
      :manual="selectedManual"
      @close-translation-tab="closeTranslationTab()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';
import AWS from 'aws-sdk';

export default {
  components: {
    EditManualsLabel: () => import('@/components/customer/manuals/EditManualsLabel'),
    ManualsLabelTranslation: () => import('@/components/customer/manuals/ManualsLabelTranslation'),
    NewManualDialog: () => import('@/components/customer/manuals/NewManualDialog'),
    delete: () => import('@/components/utils/Delete')
  },
  data() {
    return {
      overlay: false,
      isTranslating: false,
      search: '',
      selectedManual: {},
      headers: [
        {
          text: 'Manual',
          align: 'start',
          sortable: true,
          value: 'manual_s3_path'
        },
        {
          text: 'Label',
          sortable: true,
          value: 'label_value'
        },
        { text: 'Download', value: 'link', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    };
  },

  computed: {
    ...mapState(['file_transfer', 'customer'])
  },

  methods: {
    ...mapActions(['deleteS3ItemAction', 'deleteCustomerManualsAction']),
    async downloadManual(path) {
      const params = {
        Bucket: S3_BUCKETS.customer(),
        Key: path
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);

      const link = document.createElement('a');
      link.href = response;
      link.target = '_blank';
      link.click();
    },
    async deleteItem(item) {
      this.overlay = true;
      this.customer.customerManuals.splice(this.customer.customerManuals.indexOf(item), 1);
      const params = {
        Bucket: S3_BUCKETS.customer(),
        Key: item.manual_s3_path
      };
      await this.deleteS3ItemAction(params);
      await this.deleteCustomerManualsAction(item);
      this.overlay = false;
    },
    openTranslationTab(item) {
      this.selectedManual = item;
      this.isTranslating = false;
      this.$nextTick(() => {
        this.isTranslating = true;
      });
    },
    closeTranslationTab() {
      this.selectedManual = {};
      this.isTranslating = false;
    }
  }
};
</script>
